import { useEffect, useRef, useState } from "react";
import { useInViewport } from "react-in-viewport";

const useShownOnce = (options) => {
  const [shownOnce, setShownOnce] = useState(false);

  const ref = useRef();

  const { inViewport } = useInViewport(ref, options);

  useEffect(() => {
    if (inViewport) setShownOnce(true);
  }, [inViewport]);

  return { ref, inViewport, shownOnce };
};

export default useShownOnce;
