import Css from "./style.module.scss";

import InViewport from "lib/common/InViewport";
import React from "react";

const VideoFeedback = () => {
  return (
    <div className={Css.videoFeedback}>
      <InViewport>
        {({ shownOnce }) => (
          <>
            <div className={`container fadeInFromTop ${!shownOnce && "notShown"}`}>
              {/* <div className={Css.video}>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/9Tp-eaDXlNY?si=nPTa1QVCWwafrLil"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen />
              </div> */}
              <h1>AI-Powered Bookkeeping Automation <br />for Businesses and Accounting Firms</h1>
              <div className={Css.textSmall}>
                Streamline your financial processes with our comprehensive platform <br /> and AI-driven Robotic Bookkeeper for QuickBooks and Xero
              </div>
            </div>
          </>
        )}
      </InViewport>
    </div>
  );
};

export default VideoFeedback;
