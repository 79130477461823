import Css from "./style.module.scss";
import InViewport from "../InViewport";
import classNames from "classnames";

import React from "react";

const InfoBlock = (props) => {
  const {
    reverse,
    src,
    afterImage,
    title,
    subtitle,
    description,
    list,
  } = props;

  return (
    <div className={classNames(Css.infoBlock, reverse && Css.reverse)}>
      <InViewport>
        {({ shownOnce }) => (
          <div className={classNames(Css.container, "fadeIn", !shownOnce && "notShown")}>
            <div className={Css.image}>
              <img src={src} />
              {!!afterImage && <div className={Css.afterImage}>{afterImage}</div>}
            </div>
            <div className={Css.text}>
              {!!title && <div className={Css.title}>{title}</div>}
              {!!subtitle && <div className={Css.subtitle}>{subtitle}</div>}
              {!!description && <div className={Css.description}>{description}</div>}
              {!!list?.length && (
                <ul className={Css.list}>
                  {list.map((item) => <li key="item">{item}</li>)}
                </ul>
              )}
            </div>
          </div>
        )}
      </InViewport>

    </div>
  );
};

export default React.memo(InfoBlock);
