import Css from "./style.module.scss";

import React from "react";

import { IconBuildings, IconMapPin } from "lib/icons";

const GOOGLE_MAP_URL = "https://www.google.com/maps/place/919+N+Market+St,+Wilmington,+DE+19801,+USA/@39.7455021,-75.5504652,17z";

const MapStatic = ({ children }) => {
  return (
    <div className={Css.mapStatic}>
      <div className={Css.tooltip}>
        <div className={Css.content}>
          <a href={GOOGLE_MAP_URL} target="_blank">
            <IconBuildings />
            <span>Booke AI, Inc.</span>
          </a>
          <a href={GOOGLE_MAP_URL} target="_blank">
            <IconMapPin />
            <span>919 North Market St. Wilmington, DE 19801, USA</span>
          </a>
        </div>
      </div>
      {children}
    </div>
  );
};

export default MapStatic;
