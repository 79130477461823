import Css from "./style.module.scss";

import InfoBlock from "../InfoBlock";
import React from "react";

const Features = () => {
  return (
    <div id="features" className={Css.features}>
      <InfoBlock
        src="/images/fig-1.svg"
        title="24/7 AI-Driven Automated Reconciliation"
        description="Say goodbye to manual data entry and late nights. Our Robotic AI Bookkeeper works tirelessly in QuickBooks Online and Xero, automating categorization and reconciliation around the clock." />

      <InfoBlock
        reverse
        src="/images/automated-invoice-processing.svg"
        title="Smart Document Data Extraction"
        description="Transform piles of paperwork into organized data instantly. Our AI reads invoices and receipts in any language or currency, while you easily customize fields with drag-and-drop. The system learns from your history, coding transactions like a pro. Every change is tracked, giving you full control." />

      <InfoBlock
        src="/images/fig-3.svg"
        title="Proactive Error Detection"
        description="Never worry about inaccurate books again. Our AI-powered system continuously monitors for inconsistencies, alerting you to potential issues before they become problems." />

      <InfoBlock
        reverse
        src="/images/fig-5.svg"
        title="End Bookkeeping Communication Headaches"
        description={(
          <div className={Css.text}>
            <div>Stop drowning in emails and playing phone tag. Our simple portal connects accountants and business owners instantly. Share documents, ask questions, and get quick answers – all in one spot. Bookkeeping conversations made easy.</div>
            <div className={Css.storeIcons}>
              <a href="https://apps.apple.com/us/app/booke-ai-client-portal/id6467752883" target="_blank">
                <img src="/images/app-store-badge.svg" alt="Download on the Apple Store" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.bookeai.clientapp" target="_blank">
                <img src="/images/google-play-badge.svg" alt="Get it on Google Play" />
              </a>
            </div>
          </div>
        )} />
    </div>
  );
};

export default Features;
