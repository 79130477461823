import Css from "./style.module.scss";

import React from "react";

import ContactForm from "lib/common/ContactForm";
import MapStatic from "./lib/MapStatic";

const FormBlock = () => {
  return (
    <div className={Css.formBlock} id="contact_us">
      <MapStatic>
        <div className="container">
          <div className="row-m">
            <div className={Css.formContainer}>
              <div className={Css.inner}>
                <div className={Css.title}>Get in touch with us</div>
                <div className={Css.subTitle}>Make the first step to no stress accounting with Booke</div>
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </MapStatic>
    </div>
  );
};

export default FormBlock;
