import React from "react";
import classNames from "classnames";

import useShownOnce from "hooks/useShownOnce";

const InViewport = ({ children, className, threshold = 0 }) => {
  const { ref, shownOnce, inViewport } = useShownOnce({ threshold });

  return (
    <div className={classNames("InViewportComponent", className)} ref={ref}>
      {children({ shownOnce, inViewport })}
    </div>
  );
};

export default InViewport;
